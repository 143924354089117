import React from "react";

const Description = () => {
  return (
    <div className="Description">
      <h1>WHO WE ARE AND WHAT WE DO?</h1>
      <p>
        Qalay Sharan Bitumen and barrel manufacturing plant is situated in
        Erbil/ Kurdistan Region/ Iraq. Qalay Sharan Bitumen produces 500 metric
        tons of bitumen per day and supplies its products to the International
        market. We are ready to take orders from prospect buyers and guarantee
        excellent quality. Our barrel production capacity is 15,000 units per
        month and our supplies to buyers come in bulk, Jumbo Bags and steel
        barrel packaging. We take pre-orders and ensure duly supply.With growing
        global economy, infrastructure building has become a top strategic
        priority for many administrations including in emerging economies. This
        has led to a surge in demand for high quality bitumen in many markets,
        primarily to use for building new roads. With plenty available
        resources, Qalay Sharan Bitumen is aiming to fill a gap in demand that
        exists in the current global bitumen market by supplying high quality
        bitumen of different grades and specification. Understanding and meeting
        the market need is what drives our efforts and satisfaction of our
        clients is what we aspire to achieve. Expansion and extension of our
        global reach beyond limits is our vision and we continually strategize
        to facilitate and accelerate supply and delivery. This has led us to
        take an all-in-one service approach whereby commodity is produced,
        tested, packaged and made ready for delivery all within our facilities.
      </p>
    </div>
  );
};

export default Description;
