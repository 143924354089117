import React from 'react'

const Footer2 = () => {
  return (
    <div className='Footer2'>
        <h5>© 2022 All Rights Reserved Qalay Sharan</h5>
    </div>
  )
}

export default Footer2